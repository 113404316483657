<template>
  <b-modal
    id="modal-profile-update"
    ref="refModal"
    title="Cập nhật thông tin cá nhân"
    size="lg"
    ok-title="Lưu"
    cancel-title="Huỷ"
    cancel-variant="outline-secondary"
    :hide-header-close="true"
    no-close-on-backdrop
    @show="resetModal"
    @hidden="resetModal"
    @ok="onSubmit"
  >
    <!-- Body -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >

        <b-container
          fluid
          class="mb-2"
        >
          <b-row>
            <b-col cols="12">
              <!-- <avatar-view align="center" /> -->
              <image-view
                v-model="itemLocal.avatarFile"
                align="center"
                :src="itemLocal.avatar"
                :width="100"
                :height="100"
              />
            </b-col>
          </b-row>
        </b-container>

        <b-row>
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="name"
              rules="required"
            >
              <b-form-group
                label="Họ và tên"
              >
                <template
                  v-slot:label
                >
                  Họ và tên
                  <span
                    class="text-danger"
                  >
                    (*)
                  </span>
                </template>
                <b-form-input
                  id="name"
                  v-model="itemLocal.name"
                  placeholder="Nguyễn Nam Phong"
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="phone"
              rules="required"
            >
              <b-form-group
                label="Số điện thoại"
              >
                <template
                  v-slot:label
                >
                  Số điện thoại
                  <span
                    class="text-danger"
                  >
                    (*)
                  </span>
                </template>
                <b-form-input
                  id="phone"
                  v-model="itemLocal.phone"
                  placeholder="0888666888"
                  trim
                  :disabled="itemLocal.phone_verified"
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="email"
              rules="email"
            >
              <b-form-group
                label="Email"
              >
                <b-form-input
                  id="email"
                  v-model="itemLocal.email"
                  placeholder="username@homedoc.vn"
                  trim
                  :disabled="itemLocal.email_verified"
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <select-date
              v-model="itemLocal.birthday"
              :default-value="itemLocal.birthday"
              label="Ngày sinh"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <select-gender
              v-model="itemLocal.gender"
              :default-value="itemLocal.gender"
              label="Giới tính"
            />
          </b-col>
          <b-col md="6">
            <b-form-group
              label="CMND/CCCD"
            >
              <b-form-input
                id="id_number"
                v-model="itemLocal.id_number"
                placeholder="174850033"
                trim
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <select-date
              v-model="itemLocal.issue_date"
              :default-value="itemLocal.issue_date"
              label="Ngày cấp"
            />
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Nơi cấp CMND/CCCD"
            >
              <b-form-input
                id="place_of_issue"
                v-model="itemLocal.place_of_issue"
                placeholder="Cục cảnh sát"
                trim
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <select-province
              v-model="itemLocal.province"
              :default-value="itemLocal.province"
            />
          </b-col>
          <b-col md="6">
            <select-district
              v-model="itemLocal.district"
              :default-value="itemLocal.district"
              :province="itemLocal.province"
            />
          </b-col>
          <b-col md="6">
            <select-ward
              v-model="itemLocal.ward"
              :district="itemLocal.district"
              :default-value="itemLocal.ward"
            />
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Địa chỉ chi tiết"
            >
              <b-form-input
                id="address"
                v-model="itemLocal.address"
                placeholder="Số 12 Khuất Duy Tiến"
                trim
              />
            </b-form-group>
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BContainer,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';

import SelectGender from '@/views/components/SelectGender.vue';
import SelectProvince from '@/views/components/SelectProvince.vue';
import SelectDistrict from '@/views/components/SelectDistrict.vue';
import SelectWard from '@/views/components/SelectWard.vue';
import SelectDate from '@/views/components/SelectDate.vue';
import ImageView from '@/views/components/ImageView.vue';
import useProfileUpdateModal from './useProfileUpdateModal';

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BContainer,
    ValidationProvider,
    ValidationObserver,

    SelectGender,
    SelectProvince,
    SelectDistrict,
    SelectWard,
    SelectDate,
    ImageView,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModal,
      itemLocal,
      resetItemLocal,
      resetModal,
      avatarChanged,

      onSubmit,
    } = useProfileUpdateModal(props, emit, refFormObserver);

    return {
      refModal,
      itemLocal,
      resetItemLocal,
      resetModal,
      onSubmit,
      avatarChanged,
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
    };
  },
};
</script>
