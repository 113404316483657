import { ref } from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import moment from 'moment';
import userStoreModule from '../userStoreModule';

export default function useProfileUpdateModal(props, emit, refFormObserver) {
  const STORE_MODULE_NAME = 'user';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, userStoreModule);
  }

  const toastification = toast();

  const refModal = ref(null);
  const refForm = ref(refFormObserver);
  const itemLocal = ref(JSON.parse(JSON.stringify(props.item)));
  const resetItemLocal = () => {
    itemLocal.value = JSON.parse(JSON.stringify(props.item));
  };

  const resetModal = () => {
    resetItemLocal();
  };

  const avatarChanged = file => {
    console.log(file);
    itemLocal.value.avatarFile = file;
  };

  const onSubmit = async bvModalEvt => {
    try {
      bvModalEvt.preventDefault();

      const success = await refForm.value.validate();
      if (!success) {
        return;
      }

      console.log(itemLocal.value.avatarFile);

      const formData = new FormData();

      formData.append('name', itemLocal.value.name || '');
      formData.append('phone', itemLocal.value.phone || '');
      formData.append('email', itemLocal.value.email || '');
      formData.append('id_number', itemLocal.value.id_number || '');
      formData.append('place_of_issue', itemLocal.value.place_of_issue || '');
      formData.append('address', itemLocal.value.address || '');

      if (itemLocal.value.province && itemLocal.value.province.id > 0) {
        formData.append('province_id', itemLocal.value.province.id);
      }
      if (itemLocal.value.district && itemLocal.value.district.id > 0) {
        formData.append('district_id', itemLocal.value.district.id);
      }
      if (itemLocal.value.ward && itemLocal.value.ward.id > 0) {
        formData.append('ward', itemLocal.value.ward.id);
      }

      if (itemLocal.value.gender) {
        formData.append('gender_id', itemLocal.value.gender.id);
      }
      if (moment(itemLocal.value.birthday, 'DD-MM-YYYY').isValid()) {
        formData.append('birthday', itemLocal.value.birthday);
      }
      if (moment(itemLocal.value.issue_date, 'DD-MM-YYYY').isValid()) {
        formData.append('issue_date', itemLocal.value.issue_date);
      }

      if (itemLocal.value.avatarFile) {
        formData.append('avatar', itemLocal.value.avatarFile);
        console.log(itemLocal.value.avatarFile);
      }

      store
        .dispatch('user/updateProfile', formData)
        .then(response => {
          localStorage.setItem('userData', JSON.stringify(response.data));
          emit('on-item-updated', response.data);
        })
        .then(() => {
          refModal.value.toggle('#toggle-btn');
        })
        .then(() => {
          emit('refetch-data');
        })
        .then(() => {
          toastification.showToastUpdateSuccess();
        })
        .catch(error => {
          toastification.showToastError(error, refForm.value);
        });
    } catch (error) {
      console.log(error);
    }
  };

  return {
    refModal,
    refForm,
    itemLocal,
    resetItemLocal,
    resetModal,
    onSubmit,
    avatarChanged,
  };
}
