// Notification
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default function toast() {
  const toastification = useToast();

  const showToast = (title, text, icon, variant) => {
    toastification({
      component: ToastificationContent,
      props: {
        title: title || "Thông báo",
        text,
        icon,
        variant,
      },
    });
  };

  const showToastSuccess = text => {
    showToast('Thông báo', text, "BellIcon", "success");
  };

  const showToastUpdateSuccess = () => {
    showToast('Thông báo', "Dữ liệu đã được CẬP NHẬT thành công!", "BellIcon", "success");
  };

  const showToastCreateSuccess = () => {
    showToast('Thông báo', "Dữ liệu đã được TẠO thành công!", "BellIcon", "success");
  };

  const showToastDeleteSuccess = () => {
    showToast('Thông báo', "Dữ liệu đã được XÓA thành công!", "BellIcon", "success");
  };

  const showToastError = (error, form) => {
    if (form && error.response && error.response.status === 422 && error.response.data && error.response.data.errors) {
      form.setErrors(error.response.data.errors);
    } else if (error.response && error.response.status !== 200 && error.response.data && error.response.data.message && error.response.data.message.length > 0) {
      showToast('Thông báo', error.response.data.message, "AlertTriangleIcon", "danger");
    } else {
      console.log(error);
      showToast('Thông báo', "Lỗi hệ thống. Vui lòng chờ trong giây lát hoặc liên hệ hỗ trợ kỹ thuật để được trợ giúp kịp thời. Hotline liên hệ: 0888666888", "AlertTriangleIcon", "danger");
    }
  };

  const showToastErrorWithMessage = message => {
    showToast('Thông báo', message, "AlertTriangleIcon", "danger");
  };

  return {
    showToast,
    showToastSuccess,
    showToastCreateSuccess,
    showToastUpdateSuccess,
    showToastError,
    showToastDeleteSuccess,
    showToastErrorWithMessage,
  };
}
